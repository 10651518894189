import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../../components/Layout"
import StrapiRender from "../../components/StrapiRender"

export default function ProjectPage({ data }) {
  const project = data.strapiProjects

  return (
    <Layout
      Seo={{ title: project.title, description: project.support }}
      heroMain={
        <div className="flex flex-col items-center justify-end w-full h-full text-white lg:pb-12 px-4">
          <div className="pb-2 text-lg tracking-90 uppercase flex flex-row items-center">
            <div className="bg-white w-10 lg:w-20 h-px" />
            <p className="md:text-xl mx-3 font-light">
              {project.projectStatus.title}
            </p>
            <div className="bg-white w-10 lg:w-20 h-px" />
          </div>
          <h1 className="pb-4 text-center font-serif text-3xl md:text-5xl tracking-90">
            {project.title}
          </h1>
        </div>
      }
      heroSupport={project.support}
      heroImage={
        <>
          <GatsbyImage
            style={{
              gridArea: "1/1",
              filter: "brightness(90%)",
            }}
            className="w-full h-full max-w-5xl mx-auto"
            image={getImage(project.cover.image.localFile)}
            alt={project.cover.image.alternativeText}
            loading="eager"
          />
          {project.cover.caption ? (
            <p className="caption px-2 lg:px-0 pt-2 max-w-5xl mx-auto w-full">
              {project.cover.caption}
            </p>
          ) : null}
        </>
      }
      content={
        <>
          <section className="section-container pt-0 sm:pt-5 px-2">
            <div className="container flex flex-col items-center text-center space-y-12">
              <StrapiRender node={project.introduction} />
              <StrapiRender
                node={project.conceptAndDesign}
                title="Concept & Design"
              />
              <StrapiRender node={project.technical} />
            </div>
          </section>
        </>
      }
    ></Layout>
  )
}

export const query = graphql`
  query projectPageQuery($slug: String!) {
    strapiProjects(slug: { eq: $slug }) {
      title
      projectStatus {
        title
      }
      support
      slug
      cover {
        caption
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      introduction
      conceptAndDesign
      technical
    }
  }
`
