import React from "react"

export default function StrapiRender({ node, title }) {
  return (
    <div
      className="space-y-4 md:space-y-8 flex flex-col items-center"
      id="strapi-renderer"
    >
      {title && <h2 className="playfair-title">{title}</h2>}
      {node.map(
        item =>
          (item.strapi_component === "editing-components.heading" && (
            <h2 className="playfair-title">{item.heading}</h2>
          )) ||
          (item.strapi_component === "editing-components.image-gallery" && (
            <div className="flex flex-col items-center space-y-4 md:space-y-8">
              {item.images.map(image => (
                <div className="image-container">
                  <img src={image.url} alt={image.alternativeText} />
                  <div className="caption">{image.caption}</div>
                </div>
              ))}
            </div>
          )) ||
          (item.strapi_component === "editing-components.text" && (
            <p className="pb-1 sm:max-w-[500px] md:max-w-none">{item.text}</p>
          )) ||
          (item.strapi_component === "editing-components.image" && (
            <div className="image-container">
              <img src={item.image.url} alt={item.image.alternativeText} />
              <div className="caption">
                {item.caption || item.image.caption || null}
              </div>
            </div>
          )) ||
          (item.strapi_component === "editing-components.image-row" && (
            <div className="image-container !grid !grid-cols-2 gap-4 sm:w-[450px] md:w-auto">
              {item.images.map(image => (
                <div className="h-full flex flex-col">
                  <img
                    src={image.image.url}
                    alt={image.alternativeText || image.image.alternativeText}
                    className="!w-auto"
                  />
                  <div className="caption">
                    {image.caption || image.image.caption}
                  </div>
                </div>
              ))}
            </div>
          )) ||
          (item.strapi_component ===
            "editing-components.asymmetricimages-in-row" && (
            <div className="image-container !flex-row space-x-4">
              {item.image.map(image => (
                <div className="h-full flex flex-col">
                  <img
                    src={image.image.url}
                    alt={image.alternativeText || image.alternativeText}
                    className="h-full !w-auto"
                  />
                  <div className="caption">
                    {image.caption || image.image.caption}
                  </div>
                </div>
              ))}
            </div>
          )) ||
          null
      )}
    </div>
  )
}
